// much of this file was inspired by https://github.com/vercel/next.js/tree/canary/examples/with-apollo

import { useMemo } from "react";
import { ApolloClient, ApolloLink, createHttpLink, from, InMemoryCache } from "@apollo/client";
import merge from "deepmerge";
import { getUserToken } from "helpers/authenticate";
import { notification } from "antd";
import { setContext } from "@apollo/client/link/context";
import { RetryLink } from "apollo-link-retry";
import { onError } from "apollo-link-error";
import { omitDeep } from "helpers/utils";
import DebounceLink from "apollo-link-debounce";
import { SentryLink } from "apollo-link-sentry";
import { BatchHttpLink } from "@apollo/client/link/batch-http";

export const APOLLO_STATE_PROP_NAME = "__APOLLO_STATE__";
export const API_SCHEMA_CLIENT_NAME = "ApiSchema"; // Used in context objects to choose which endpoint to select from.

// Link 1 - Debounce
const DEFAULT_DEBOUNCE_TIMEOUT = 1000;
const debounceLink = new DebounceLink(DEFAULT_DEBOUNCE_TIMEOUT);

// Link 2 - Sentry
const sentryLink = new SentryLink();

// Link 3 - Retry
const retryLink = new RetryLink({
  delay: {
    initial: 300,
    max: Infinity,
    jitter: true,
  },
  attempts: {
    max: 5,
    retryIf: (error) => !!error && error.statusCode !== 401,
  },
});

// Link 4 - Handle Error link
const errLink = onError(({ graphQLErrors, networkError, operation, response }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ name }) => {
      // UnauthorizedErrors are expected when an unauthenticated user browses to a protected route, and handled by <Gatekeeper>
      if (name === "UnauthorizedError") {
        // https://www.apollographql.com/docs/link/links/error/#ignoring-errors
        response!.errors = undefined;
      }
    });
  if (networkError) {
    const serverResponse = operation.getContext().response;
    if (serverResponse?.status === 401) {
      networkError = undefined;
    }
  }
});

// Link 5 - Clean Typename
// Inspired by https://stackoverflow.com/questions/47211778/cleaning-unwanted-fields-from-graphql-responses/51380645#51380645
const cleanTypenameLink = new ApolloLink((operation, forward) => {
  if (operation.variables) {
    operation.variables = omitDeep(operation.variables, "__typename");
  }
  return forward(operation).map((data) => {
    return data;
  });
});

// Link 6 - Auth and API Endpoint selector link
export const _getAuthorizationHeader = async (clientName?: string) => {
  const token = await getUserToken();
  if (token) {
    return clientName === API_SCHEMA_CLIENT_NAME ? token : `Bearer ${token}`;
  }
};

const authLink = setContext(async (_, previousContext) => {
  const { clientName, headers } = previousContext;
  const authorization = await _getAuthorizationHeader(clientName);
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization,
    },
  };
});

// Using this link instead of createHttpLink enables queries to batch
const httpLink = new BatchHttpLink({
  uri: `${process.env.NEXT_PUBLIC_API_REST_URL}:${process.env.NEXT_PUBLIC_API_REST_PORT}/graphql`,
  batchMax: 5, // 5 in 1 network call seems like a good number
  batchInterval: 20, // amount of time in ms apolllo waits to collect queries to batch
  batchDebounce: true, // reset timer if receives another query
});

// This is the new API Schema repo which uses AppSync prototype PD-2397
// Note - apparently app sync does not support query batching
const httpLinkApiSchema = createHttpLink({
  uri: `${process.env.NEXT_API_SCHEMA_GRAPHQL_URL}`,
});

const authApiLink = authLink.concat(
  ApolloLink.split(
    (operation) => operation.getContext().clientName === API_SCHEMA_CLIENT_NAME,
    httpLinkApiSchema, // api-schema repo endpoint setup with AWS App Sync
    httpLink // <= otherwise will send to this
  )
);

let apolloClient;

function createApolloClient() {
  return new ApolloClient({
    ssrMode: typeof window === "undefined",
    queryDeduplication: true, // supposedly the default, but lets be explicit
    // compose multiple links together
    link: from([debounceLink, sentryLink, retryLink as any, errLink, cleanTypenameLink, authApiLink]),
    cache: new InMemoryCache({
      addTypename: true,
      typePolicies: {
        Project: {
          fields: {
            members: {
              merge: (_, incoming) => incoming,
            },
          },
        },
      },
    }),
  });
}

// https://github.com/vercel/next.js/blob/canary/examples/with-apollo/lib/apolloClient.js
export function initializeApollo(initialState = null) {
  const _apolloClient = apolloClient ?? createApolloClient();

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();

    // Merge the existing cache into data passed from getStaticProps/getServerSideProps
    const data = merge(initialState as any, existingCache);

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data);
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === "undefined") return _apolloClient;
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;

  return _apolloClient;
}

export function addApolloState(client, pageProps) {
  if (pageProps?.props) {
    pageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract();
  }

  return pageProps;
}

export function useApollo(pageProps) {
  const state = pageProps[APOLLO_STATE_PROP_NAME];
  return useMemo(() => initializeApollo(state), [state]);
}

// Manually entering our bearer token is super annoying on the GQL playground
// Let's make this easier.
let cursor = 0;

const konamiCodeListener = () => {
  const KONAMI_CODE = [
    "ArrowUp",
    "ArrowUp",
    "ArrowDown",
    "ArrowDown",
    "ArrowLeft",
    "ArrowRight",
    "ArrowLeft",
    "ArrowRight",
    "b",
    "a",
  ];
  if (typeof window !== "undefined") {
    document.addEventListener("keydown", async (e) => {
      cursor = e.key == KONAMI_CODE[cursor] ? cursor + 1 : 0;
      if (cursor == KONAMI_CODE.length) {
        const authorization = await _getAuthorizationHeader();
        if (authorization) {
          const httpHeadersForGQLPlayground = { authorization };
          notification.open({
            style: { border: "2px solid black" },
            message: "HTTP headers copied",
            description: "HTTP headers copied to clipboard.",
            duration: 5,
          });
          // copy to clipboard
          navigator.clipboard.writeText(JSON.stringify(httpHeadersForGQLPlayground, null, 2));
        }
      }
    });
  }
};

// only listen for the konami code if we are in dev mode.
if (
  typeof window !== "undefined" &&
  (!process.env.NODE_ENV || process.env.NODE_ENV === "development" || window.location.hostname === "app.dev.projectdado.com")
) {
  konamiCodeListener();
}
